var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('hk-card',{attrs:{"no-margin":"","min-width":320}},[_c('div',{staticClass:"card-header",attrs:{"slot":"header"},slot:"header"},[_vm._v("\n\t\t\tPlayers\n\t\t\t"),_c('a',{staticClass:"btn btn-sm",on:{"click":function($event){_vm.players_dialog = true}}},[_c('i',{staticClass:"fas fa-plus green mr-1",attrs:{"aria-hidden":"true"}}),_vm._v(" Add players\n\t\t\t")])]),_c('div',{staticClass:"card-body"},[(_vm.campaign_players.length)?_c('ul',{staticClass:"entities hasImg"},_vm._l((_vm.campaign_players),function(player){return _c('li',{key:player.key},[_c('span',{staticClass:"img",style:({
							backgroundImage: _vm.player_avatar(player)
								? 'url(\'' + _vm.player_avatar(player) + '\')'
								: '',
						})},[(!_vm.player_avatar(player))?_c('i',{staticClass:"hki-player",attrs:{"aria-hidden":"true"}}):_vm._e()]),_c('div',{class:{ red: _vm.inOtherCampaign(player.campaign_id) }},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(player.character_name)+"\n\t\t\t\t\t\t"),(_vm.inOtherCampaign(player.campaign_id))?_c('span',{staticClass:"d-none d-md-inline ml-1 neutral-2"},[_c('small',[_vm._v("Different Campaign")])]):_vm._e()]),_c('div',{staticClass:"actions items-center pr-0"},[_c('a',{staticClass:"btn btn-sm bg-neutral-5",on:{"click":function($event){return _vm.removePlayer(player)}}},[_c('i',{staticClass:"fas fa-trash-alt",attrs:{"aria-hidden":"true"}}),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center right"}},[_vm._v("\n\t\t\t\t\t\t\t\tRemove from campaign\n\t\t\t\t\t\t\t")])],1)])])}),0):_c('div',[_c('p',[_vm._v("There are no players in this campaign yet.")]),_c('a',{staticClass:"btn btn-block",on:{"click":function($event){_vm.players_dialog = true}}},[_vm._v("Add players")])])]),_c('div',{staticClass:"card-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"label":"Close","no-caps":""}})],1)]),_c('q-dialog',{model:{value:(_vm.players_dialog),callback:function ($$v) {_vm.players_dialog=$$v},expression:"players_dialog"}},[_c('hk-card',{attrs:{"header":"All Players","min-width":300}},[_c('div',{staticClass:"card-header",attrs:{"slot":"header"},slot:"header"},[_vm._v("\n\t\t\t\tAdd players\n\t\t\t\t"),_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"icon":"close","no-caps":"","flat":"","dense":""}})],1),_c('div',{staticClass:"card-body"},[_c('ul',{staticClass:"entities hasImg"},_vm._l((_vm.players),function(player){return _c('li',{key:player.key},[_c('span',{staticClass:"img",style:({
								backgroundImage: _vm.player_avatar(player)
									? 'url(\'' + _vm.player_avatar(player) + '\')'
									: '',
							})},[(!_vm.player_avatar(player))?_c('i',{staticClass:"hki-player",attrs:{"aria-hidden":"true"}}):_vm._e()]),_vm._v("\n\n\t\t\t\t\t\t"+_vm._s(player.character_name)+"\n\n\t\t\t\t\t\t"),(_vm.inOtherCampaign(player.campaign_id))?_c('span',[_c('span',{staticClass:"d-none d-md-inline ml-1 neutral-3 pr-2"},[_c('small',[_vm._v("Different Campaign")])])]):(_vm.checkPlayer(player.campaign_id))?_c('span',[_c('i',{staticClass:"fas fa-check pr-2 neutral-2",attrs:{"aria-hidden":"true"}})]):_c('div',{staticClass:"actions items-center pr-0"},[_c('a',{staticClass:"btn btn-sm bg-neutral-5",on:{"click":function($event){return _vm.addPlayer(player.key)}}},[_c('i',{staticClass:"fas fa-plus green",attrs:{"aria-hidden":"true"}}),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v(" Add character ")])],1)])])}),0)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }