import { render, staticRenderFns } from "./ImportUserContent.vue?vue&type=template&id=47ab3866&scoped=true"
import script from "./ImportUserContent.vue?vue&type=script&lang=js"
export * from "./ImportUserContent.vue?vue&type=script&lang=js"
import style0 from "./ImportUserContent.vue?vue&type=style&index=0&id=47ab3866&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47ab3866",
  null
  
)

export default component.exports
import QFile from 'quasar/src/components/file/QFile.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QFile,QIcon,QForm,QInput,QBtn,QTable,QLinearProgress,QExpansionItem,QItemSection,QList,QItem,QDialog});qInstall(component, 'directives', {ClosePopup});
