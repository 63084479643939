var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.current)?_c('div',[_c('q-btn-toggle',{staticClass:"mb-3",attrs:{"value":_vm.roll_type,"spread":"","no-caps":"","dark":_vm.$store.getters.theme === 'dark',"dense":"","square":"","toggle-color":"primary","options":[
			{ label: 'Attack', value: 'attack' },
			{ label: 'Save', value: 'save' },
			{ label: 'Heal', value: 'heal' },
		]},on:{"input":_vm.setType}}),(_vm.roll_type === 'attack')?_c('q-input',{attrs:{"dark":_vm.$store.getters.theme === 'dark',"dense":"","filled":"","square":"","type":"number","label":"Attack bonus"},model:{value:(_vm.attack_bonus),callback:function ($$v) {_vm.attack_bonus=$$v},expression:"attack_bonus"}}):_vm._e(),(_vm.roll_type === 'save')?_c('div',{staticClass:"d-flex justify-content-between"},[_c('q-select',{staticClass:"mr-1",attrs:{"dark":_vm.$store.getters.theme === 'dark',"dense":"","filled":"","square":"","map-options":"","emit-value":"","label":"Save ability","options":_vm.abilities},model:{value:(_vm.save_ability),callback:function ($$v) {_vm.save_ability=$$v},expression:"save_ability"}}),_c('q-input',{attrs:{"dark":_vm.$store.getters.theme === 'dark',"dense":"","filled":"","square":"","type":"number","label":"Save DC"},model:{value:(_vm.save_dc),callback:function ($$v) {_vm.save_dc=$$v},expression:"save_dc"}})],1):_vm._e(),_c('h3',[_vm._v("\n\t\tRolls\n\t\t"),_c('a',{on:{"click":_vm.addRoll}},[_c('i',{staticClass:"fas fa-plus green",attrs:{"aria-hidden":"true"}})])]),_vm._l((_vm.custom_rolls),function(roll,index){return _c('div',{key:`roll-${index}`,staticClass:"custom-roll"},[_c('q-input',{ref:index,refInFor:true,attrs:{"dark":_vm.$store.getters.theme === 'dark',"filled":"","square":"","dense":"","label":"Roll","autocomplete":"off","lazy-rules":"","rules":[
				(val) => !!val || 'Roll is required',
				(val) => val.match(/^[0-9]+d[0-9]+(\+[0-9])?$/) || 'Allowed format: 2d6 or 2d6+1',
			]},model:{value:(_vm.custom_rolls[index].roll),callback:function ($$v) {_vm.$set(_vm.custom_rolls[index], "roll", $$v)},expression:"custom_rolls[index].roll"}}),(_vm.roll_type !== 'heal')?_c('hk-dmg-type-select',{staticClass:"ml-1",attrs:{"dense":"","required":"","label":"Damage type"},model:{value:(_vm.custom_rolls[index].damage_type),callback:function ($$v) {_vm.$set(_vm.custom_rolls[index], "damage_type", $$v)},expression:"custom_rolls[index].damage_type"}}):_vm._e(),(index > 0)?_c('a',{staticClass:"remove",on:{"click":function($event){return _vm.removeRoll(index)}}},[_c('i',{staticClass:"fas fa-trash-alt",attrs:{"aria-hidden":"true"}})]):_vm._e()],1)}),(_vm.targeted.length > 0)?_c('hk-roll',{staticClass:"full-width",attrs:{"tooltip":"Roll","tooltipPosition":"right"},on:{"roll":function($event){return _vm.roll($event)}}},[_c('span',{staticClass:"btn btn-block"},[_vm._v("Roll")])]):_c('div',{staticClass:"text-center text-bold red"},[_vm._v("Select one ore more targets")])],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }