var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',{staticClass:"benefits"},_vm._l((_vm.benefits),function(benefit,key){return _c('li',{key:key,staticClass:"benefit",class:{ pointer: key === 'storage', open: _vm.show_storage },on:{"click":function($event){key === 'storage' ? (_vm.show_storage = !_vm.show_storage) : null}}},[(typeof _vm.tier.benefits[key] === 'boolean')?_c('i',{staticClass:"fas",class:_vm.tier.benefits[key] ? 'fa-check green' : 'fa-times neutral-3',attrs:{"aria-hidden":"true"}}):[(_vm.tier.benefits[key] === 'infinite')?_c('i',{staticClass:"green far fa-infinity",attrs:{"aria-hidden":"true"}}):_c('strong',{class:_vm.tier.benefits[key] === '-' ? 'neutral-3' : 'green'},[_vm._v(_vm._s(_vm.tier.benefits[key]))])],_c('span',{staticClass:"flex justify-between items-center"},[_vm._v("\n\t\t\t\t"+_vm._s(benefit.title)+"\n\t\t\t\t"),(key === 'storage')?_c('i',{staticClass:"mr-2 far fa-chevron-down",attrs:{"aria-hidden":"true"}}):_vm._e()])],2)}),0),_c('q-slide-transition',[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_storage),expression:"show_storage"}]},_vm._l((_vm.storage),function(storage_type){return _c('li',{key:storage_type,staticClass:"storage"},[(_vm.tier.benefits[storage_type] === 'infinite')?_c('i',{staticClass:"green far fa-infinity",attrs:{"aria-hidden":"true"}}):_c('span',{staticClass:"count",class:_vm.content_count[storage_type] > _vm.tier.benefits[storage_type]
							? 'red'
							: _vm.content_count[storage_type] === _vm.tier.benefits[storage_type]
							? 'neutral-2'
							: 'green'},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.tier.benefits[storage_type])+"\n\t\t\t\t")]),_c('span',{staticClass:"truncate neutral-4"},[_c('span',{staticClass:"neutral-1"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(storage_type.slice(0, -1).capitalize())+_vm._s(_vm.tier.benefits[storage_type] > 1 || _vm.tier.benefits[storage_type] === "infinite"
								? "s"
								: "")+"\n\t\t\t\t\t")]),(storage_type === 'encounters')?_c('span',{staticClass:"neutral-4"},[_vm._v(" (per campaign) ")]):_vm._e()])])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }