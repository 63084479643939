import { render, staticRenderFns } from "./Loot.vue?vue&type=template&id=1b586177&scoped=true"
import script from "./Loot.vue?vue&type=script&lang=js"
export * from "./Loot.vue?vue&type=script&lang=js"
import style0 from "./Loot.vue?vue&type=style&index=0&id=1b586177&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b586177",
  null
  
)

export default component.exports
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QList from 'quasar/src/components/item/QList.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QTooltip,QInput,QList,QForm,QExpansionItem,QItemSection,QIcon,QBtn});
