var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.entity.reminders && Object.keys(_vm.entity.reminders).length > 0)?_c('div',{staticClass:"reminders truncate-chip-labels"},[_vm._l((_vm.entity.reminders),function(reminder,key){return [(reminder)?_c('q-chip',{key:key,class:'bg-' + reminder.color,attrs:{"clickable":"","square":"","size":"12px","icon":reminder.icon},on:{"click":function($event){return _vm.setDrawer({
					show: true,
					type: 'drawers/encounter/reminders/Reminder',
					data: {
						key,
						entity: _vm.entity,
					},
				})}}},[(reminder.rounds)?_c('q-avatar',[_c('b',[_vm._v(_vm._s(reminder.rounds))]),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v("\n\t\t\t\t\tEnds in "+_vm._s(reminder.rounds)+" rounds\n\t\t\t\t")])],1):_vm._e(),_c('q-item-label',[_vm._v(_vm._s(_vm.title(reminder)))])],1):_vm._e()]})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }