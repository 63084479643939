import { render, staticRenderFns } from "./ViewEntity.vue?vue&type=template&id=2521bb61&scoped=true"
import script from "./ViewEntity.vue?vue&type=script&lang=js"
export * from "./ViewEntity.vue?vue&type=script&lang=js"
import style0 from "./ViewEntity.vue?vue&type=style&index=0&id=2521bb61&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2521bb61",
  null
  
)

export default component.exports
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QResizeObserver from 'quasar/src/components/resize-observer/QResizeObserver.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QTooltip,QResizeObserver,QDialog});
