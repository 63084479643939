var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.entity.conditions && Object.keys(_vm.entity.conditions).length > 0)?_c('div',{staticClass:"conditions"},[_vm._l((_vm.entity.conditions),function(condition,key){return [(_vm.conditions[key])?_c('div',{key:key,on:{"click":function($event){return _vm.setDrawer({
					show: true,
					type: 'drawers/encounter/Condition',
					data: {
						condition: key,
						entity: _vm.entity,
					},
				})}}},[(key == 'exhaustion')?_c('span',{staticClass:"n"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.entity.conditions[key])+"\n\t\t\t")]):_vm._e(),_c('i',{staticClass:"icon",class:`hki-${key}`,attrs:{"aria-hidden":"true"}}),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v("\n\t\t\t\t"+_vm._s(key.capitalize())+"\n\t\t\t")])],1):_vm._e()]})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }