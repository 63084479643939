var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"light",class:_vm.background && _vm.lightning && _vm.showWeather ? `bg-white` : `bg-neutral-5`,style:({
		'--backgroundImage':
			_vm.background && !_vm.backgroundVideo && !_vm.backgroundYoutube ? 'url(\'' + _vm.background + '\')' : '',
	})},[_c('div',{staticClass:"weather-wrapper",class:[
			_vm.lightning && _vm.showWeather ? `lightning-effect lightning-effect__${_vm.lightning}` : '',
			_vm.quake && _vm.showWeather ? `quake quake-${_vm.quake}` : '',
		]},[_vm._t("default"),(_vm.weather && _vm.showWeather)?[(_vm.weather.fog > 0)?_c('Fog',{attrs:{"intensity":_vm.weather.fog,"smoke":_vm.weather.smoke,"audio":_vm.audio}}):_vm._e(),(_vm.weather.sand > 0)?_c('Sand',{attrs:{"intensity":_vm.weather.sand,"audio":_vm.audio}}):_vm._e(),(_vm.weather.rain > 0)?_c('Rain',{attrs:{"intensity":_vm.weather.rain,"audio":_vm.audio}}):_vm._e(),(_vm.weather.hail > 0)?_c('Hail',{attrs:{"intensity":_vm.weather.hail,"audio":_vm.audio}}):_vm._e(),(_vm.weather.snow > 0)?_c('Snow',{attrs:{"intensity":_vm.weather.snow,"audio":_vm.audio}}):_vm._e(),(_vm.weather.ash > 0)?_c('Ash',{attrs:{"intensity":_vm.weather.ash,"audio":_vm.audio}}):_vm._e()]:_vm._e(),(_vm.backgroundVideo)?_c('video',{staticClass:"bg-video",attrs:{"src":_vm.backgroundVideo,"alt":"Background video","playsinline":"","autoplay":"","loop":""},domProps:{"muted":_vm.muted}}):_vm._e(),(_vm.backgroundYoutube)?_c('iframe',{ref:"youtube",staticClass:"youtube-player",attrs:{"src":`${_vm.backgroundYoutube}?autoplay=1&mute=${_vm.mute_youtube}&playsinline=1&controls=0`,"title":"YouTube video player","allow":"autoplay"}}):_vm._e(),(_vm.backgroundYoutube)?_c('q-resize-observer',{on:{"resize":_vm.setSize}}):_vm._e()],2),(_vm.audio && _vm.thunder_interval)?_c('audio',{ref:"thunder",attrs:{"src":_vm.thunder_audio,"autoplay":""}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }