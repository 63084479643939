var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.live ? 'Weather' : 'div',{tag:"component",staticClass:"full-height",attrs:{"show-weather":_vm.showWeather,"muted":_vm.muted,"weather":_vm.campaign.weather,"background":_vm.background_image,"background-video":_vm.background_video,"background-youtube":_vm.background_youtube}},[(_vm.campaign.sharing && _vm.live)?_c('Sharing',{attrs:{"sharing":_vm.campaign.sharing}}):_vm._e(),(_vm.width > 576)?_c('div',{staticClass:"track desktop",class:{ isLive: _vm.live }},[_c('div',{staticClass:"players"},[_c('h3',{staticClass:"text-shadow"},[_vm._v("Campaign Players")]),_c('q-scroll-area',{attrs:{"dark":_vm.$store.getters.theme === 'dark',"thumb-style":{ width: '5px' }}},[_c('ViewPlayers',{attrs:{"userId":_vm.userId,"campaignId":_vm.$route.params.campid,"campaign":_vm.campaign,"players":_vm.players}})],1)],1),_c('div',{staticClass:"side"},[_c('h3',{staticClass:"text-shadow"},[_vm._v("Campaign wide meters")]),_c('q-scroll-area',{attrs:{"dark":_vm.$store.getters.theme === 'dark',"thumb-style":{ width: '5px' }}},[_c('Meters',{attrs:{"entities":_vm.campaign.players,"players":_vm.players,"campaign":true,"npcs":{}}})],1)],1),(_vm.live)?_c('div',{staticClass:"shares-bar",class:{ shown: _vm.showShares }},[_c('div',{staticClass:"show",on:{"click":function($event){_vm.showShares = !_vm.showShares}}},[_c('i',{staticClass:"fas fa-chevron-left",attrs:{"aria-hidden":"true"}})]),_c('Shares',{attrs:{"shares":_vm.shares,"players":_vm.players}})],1):_vm._e()]):_c('div',{staticClass:"track mobile"},[_c('div',{staticClass:"bg-neutral-10"},[_c('q-select',{attrs:{"dark":_vm.$store.getters.theme === 'dark',"filled":"","square":"","options":_vm.panels},scopedSlots:_vm._u([{key:"selected",fn:function(){return [_c('q-item',[_c('q-item-section',{attrs:{"avatar":""}},[_c('q-icon',{attrs:{"name":_vm.panels.filter((item) => {
										return item.value === _vm.panel;
									})[0].icon}})],1),_c('q-item-section',[_c('q-item-label',{domProps:{"textContent":_vm._s(
									_vm.panels.filter((item) => {
										return item.value === _vm.panel;
									})[0].label
								)}})],1)],1)]},proxy:true},{key:"option",fn:function(scope){return [_c('q-item',{directives:[{name:"ripple",rawName:"v-ripple"},{name:"close-popup",rawName:"v-close-popup"}],attrs:{"clickable":"","active":_vm.panel === scope.opt.value},on:{"click":function($event){_vm.panel = scope.opt.value}}},[_c('q-item-section',{attrs:{"avatar":""}},[_c('q-icon',{attrs:{"name":scope.opt.icon}})],1),_c('q-item-section',[_c('q-item-label',{domProps:{"textContent":_vm._s(scope.opt.label)}})],1)],1)]}}]),model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}})],1),_c('q-tab-panels',{staticClass:"transparent-bg",attrs:{"animated":"","swipeable":"","infinite":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('q-tab-panel',{attrs:{"name":"players"}},[_c('ViewPlayers',{attrs:{"userId":_vm.userId,"campaignId":_vm.$route.params.campid,"campaign":_vm.campaign,"players":_vm.players}})],1),_c('q-tab-panel',{attrs:{"name":"meters"}},[_c('Meters',{attrs:{"entities":_vm.campaign.players,"players":_vm.players,"campaign":true,"npcs":{}}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }