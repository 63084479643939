import { render, staticRenderFns } from "./Requests.vue?vue&type=template&id=2efcf58f&scoped=true"
import script from "./Requests.vue?vue&type=script&lang=js"
export * from "./Requests.vue?vue&type=script&lang=js"
import style0 from "./Requests.vue?vue&type=style&index=0&id=2efcf58f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2efcf58f",
  null
  
)

export default component.exports
import QList from 'quasar/src/components/item/QList.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QList});
