var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('div',[_c('h3',{staticClass:"d-flex justify-content-between"},[_vm._v("\n\t\t"+_vm._s(_vm.item.name)+"\n\t")]),_c('i',{staticClass:"mb-3 d-block",attrs:{"aria-hidden":"true"}},[_vm._v("\n\t\t"+_vm._s(_vm.item.type)+", \n\t\t"),_c('span',{class:{ 
			'white': _vm.item.rarity == 'common',
			'green': _vm.item.rarity == 'uncommon',
			'blue': _vm.item.rarity == 'rare',
			'purple': _vm.item.rarity == 'very rare',
			'orange': _vm.item.rarity == 'legendary',
			'red-light': _vm.item.rarity == 'artifact',
			}},[_vm._v("\n\t\t\t"+_vm._s(_vm.item.rarity)+"\n\t\t")]),(_vm.item.requires_attunement)?[_vm._v(" ( "+_vm._s(_vm.item.requires_attunement)+" )")]:_vm._e()],2),_c('div',{staticStyle:{"white-space":"pre-line"}},[_vm._v(_vm._s(_vm.item.desc))]),_vm._l((_vm.item.tables),function(table,index){return _c('div',{key:index,staticClass:"mt-3"},[(table.name)?_c('h6',[_vm._v(_vm._s(table.name))]):_vm._e(),_c('table',{staticClass:"table"},[_c('thead',_vm._l((table.header),function(head){return _c('th',{key:head},[_vm._v(_vm._s(head))])}),0),_c('tbody',_vm._l((table.rows),function(row,i){return _c('tr',{key:i},_vm._l((table.rows[i].columns),function(col,i){return _c('td',{key:i},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(col)+"\n\t\t\t\t\t")])}),0)}),0)])])})],2):_c('hk-loader',{attrs:{"name":"item"}})
}
var staticRenderFns = []

export { render, staticRenderFns }