import { render, staticRenderFns } from "./Entities.vue?vue&type=template&id=664009c1&scoped=true"
import script from "./Entities.vue?vue&type=script&lang=js"
export * from "./Entities.vue?vue&type=script&lang=js"
import style0 from "./Entities.vue?vue&type=style&index=0&id=664009c1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "664009c1",
  null
  
)

export default component.exports
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QTh from 'quasar/src/components/table/QTh.js';
import QResizeObserver from 'quasar/src/components/resize-observer/QResizeObserver.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QField from 'quasar/src/components/field/QField.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QTooltip,QBtnToggle,QInput,QIcon,QTable,QTr,QTd,QBtn,QBadge,QTh,QResizeObserver,QDialog,QForm,QField});qInstall(component, 'directives', {ClosePopup});
