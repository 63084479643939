import { render, staticRenderFns } from "./TargetItem.vue?vue&type=template&id=4c7dff85&scoped=true"
import script from "./TargetItem.vue?vue&type=script&lang=js"
export * from "./TargetItem.vue?vue&type=script&lang=js"
import style0 from "./TargetItem.vue?vue&type=style&index=0&id=4c7dff85&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c7dff85",
  null
  
)

export default component.exports
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QColor from 'quasar/src/components/color/QColor.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPopupProxy,QInput,QBtn,QColor,QIcon,QLinearProgress,QTooltip});qInstall(component, 'directives', {ClosePopup});
