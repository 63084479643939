import { render, staticRenderFns } from "./NPCs.vue?vue&type=template&id=1292afda&scoped=true"
import script from "./NPCs.vue?vue&type=script&lang=js"
export * from "./NPCs.vue?vue&type=script&lang=js"
import style0 from "./NPCs.vue?vue&type=style&index=0&id=1292afda&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1292afda",
  null
  
)

export default component.exports
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QCheckbox,QTooltip,QIcon});
