var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.custom_content && _vm.custom_content.length && _vm.content.length === 2)?_c('q-btn-toggle',{staticClass:"mb-3",attrs:{"value":_vm.copy_resource,"spread":"","no-caps":"","toggle-color":"primary","options":[
			{ label: `Custom ${_vm.type === 'monster' ? 'NPC' : _vm.type}s`, value: 'custom' },
			{ label: `SRD ${_vm.type}s`, value: 'srd' },
		]},on:{"input":function($event){return _vm.changeCopyResource($event)}}}):_vm._e(),_c('hk-input',{staticClass:"mb-3",attrs:{"label":_vm.copy_resource === 'custom'
				? `Search ${_vm.type === 'monster' ? 'NPCs' : _vm.type}`
				: `Search ${_vm.type}`,"type":"text","autocomplete":"off","error":!!_vm.noResult,"error-message":_vm.noResult},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();$event.stopPropagation();return _vm.search.apply(null, arguments)},"change":_vm.search},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}},[(_vm.copy_resource === 'srd')?_c('button',{staticClass:"btn btn",attrs:{"slot":"before"},on:{"click":function($event){_vm.show_filter = !_vm.show_filter}},slot:"before"},[_c('q-icon',{attrs:{"name":"fas fa-filter","size":"xs"}})],1):_vm._e(),_c('button',{staticClass:"btn btn-sm btn-clear",attrs:{"slot":"append"},on:{"click":function($event){return _vm.search()}},slot:"append"},[_c('q-icon',{attrs:{"name":"fas fa-search","size":"xs"}})],1)]),(_vm.copy_resource === 'srd')?_c('q-slide-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_filter),expression:"show_filter"}],staticClass:"filter"},[_c('h3',[_vm._v("Filter "+_vm._s(_vm.type)+"s")]),_c('hk-filter',{attrs:{"type":_vm.type},on:{"change":function($event){return _vm.search()}},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)]):_vm._e(),_c('q-list',{attrs:{"dark":_vm.$store.getters.theme === 'dark'}},_vm._l((_vm.searchResults),function(result,index){return _c('q-item',{key:index,staticClass:"bg-neutral-8",attrs:{"disabled":_vm.copy_resource === 'custom'
					? _vm.disabledCustom.includes(result.key)
					: _vm.disabledSrd.includes(result._id)}},[_c('q-item-section',[_vm._v("\n\t\t\t\t"+_vm._s(result.name.capitalizeEach())+"\n\t\t\t")]),_c('q-item-section',{attrs:{"avatar":""}},[(!_vm.disabledCustom.includes(result.key))?_c('a',{staticClass:"btn btn-sm bg-neutral-5",on:{"click":function($event){return _vm.copy(_vm.copy_resource === 'custom' ? result.key : result._id)}}},[_c('i',{staticClass:"fas",class:`fa-${_vm.button}`,attrs:{"aria-hidden":"true"}})]):_vm._e()])],1)}),1),(_vm.copy_resource === 'srd' && _vm.totalPages > 1)?_c('q-pagination',{staticClass:"mt-3",attrs:{"max":_vm.totalPages,"max-pages":5,"flat":"","direction-links":""},on:{"input":function($event){return _vm.fetchApiContent()}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }