import { render, staticRenderFns } from "./ReminderForm.vue?vue&type=template&id=733174ba&scoped=true"
import script from "./ReminderForm.vue?vue&type=script&lang=js"
export * from "./ReminderForm.vue?vue&type=script&lang=js"
import style0 from "./ReminderForm.vue?vue&type=style&index=0&id=733174ba&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "733174ba",
  null
  
)

export default component.exports
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QField from 'quasar/src/components/field/QField.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QInput,QSelect,QField,QOptionGroup,QIcon,QTooltip});
