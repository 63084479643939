var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.current)?_c('div',{attrs:{"id":"current"}},[_c('div',{staticClass:"bg-neutral-6 current"},[_c('div',[_vm._v("Up now:")]),_c('TargetItem',{attrs:{"item":_vm.current.key}}),_c('a',{on:{"click":function($event){return _vm.setDrawer({
					show: true,
					type: 'combat/TargetInfo',
					data: { key: _vm.current.key },
				})}}},[_c('q-icon',{attrs:{"name":"info"}})],1)],1),(
			(_vm.current.entityType === 'player' || _vm.current.entityType === 'companion') &&
			_vm.current.curHp === 0 &&
			!_vm.current.stable &&
			!_vm.current.dead
		)?_c('div',{staticClass:"saves bg-neutral-8"},[_c('DeathSaves',{attrs:{"target":_vm.current}})],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }