var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('p',[_vm._v("\n\t\tThe public initiative list is accessible through a link you can share with your party, or you\n\t\tcan put it up on a second screen for your party to see. In here players can follow the\n\t\tencounter, see who's turn it is and what the status of the entities within the encounter is.\n\t\tBelow you can determine what should be visible on the public initiative list.\n\t")]),_c('a',{staticClass:"btn bg-neutral-4 mb-3",on:{"click":function($event){return _vm.setDrawer({ show: true, type: 'PlayerLink' })}}},[_vm._v("\n\t\tShare your adventures\n\t")]),_vm._l((_vm.types),function({ name, type_settings },type_key){return _c('div',{key:type_key},[(name)?_c('h3',{staticClass:"mt-3 mb-1"},[_vm._v(_vm._s(name))]):_vm._e(),_vm._l((type_settings),function(setting,index){return _c('q-select',{key:`${type_key}-${index}`,staticClass:"mb-1",attrs:{"dark":_vm.$store.getters.theme === 'dark',"filled":"","square":"","options":setting.options,"value":index},scopedSlots:_vm._u([{key:"option",fn:function(scope){return [_c('q-item',{directives:[{name:"ripple",rawName:"v-ripple"},{name:"close-popup",rawName:"v-close-popup"}],attrs:{"clickable":"","active":_vm.selected(
							scope.opt.value,
							_vm.settings[setting.entity] ? _vm.settings[setting.entity][setting.key] : undefined
						)},on:{"click":function($event){return _vm.setSetting(setting.entity, setting.key, scope.opt.value)}}},[_c('q-item-section',[_c('q-item-label',{domProps:{"textContent":_vm._s(scope.opt.name)}})],1),_c('q-item-section',{attrs:{"avatar":""}},[_c('q-icon',{class:scope.opt.color,attrs:{"name":scope.opt.icon,"size":"small"}})],1)],1)]}}],null,true)},[_c('q-item',{attrs:{"slot":"selected","dark":_vm.$store.getters.theme === 'dark'},slot:"selected"},[_c('q-item-section',{attrs:{"avatar":""}},[_c('q-icon',{staticClass:"neutral-2",attrs:{"name":setting.icon,"size":"large"}})],1),_c('q-item-section',{staticClass:"neutral-2 truncate"},[_c('q-item-label',[_vm._v(_vm._s(setting.name))]),_c('q-item-label',{attrs:{"caption":""}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.displaySetting(
								type_key,
								setting.key,
								_vm.settings[setting.entity] ? _vm.settings[setting.entity][setting.key] : undefined
							).name)+"\n\t\t\t\t\t")])],1),_c('q-item-section',{attrs:{"side":""}},[_c('q-icon',{class:_vm.displaySetting(
								type_key,
								setting.key,
								_vm.settings[setting.entity] ? _vm.settings[setting.entity][setting.key] : undefined
							).color,attrs:{"name":_vm.displaySetting(
								type_key,
								setting.key,
								_vm.settings[setting.entity] ? _vm.settings[setting.entity][setting.key] : undefined
							).icon,"size":"medium"}})],1)],1),(setting.info)?_c('hk-popover',{attrs:{"slot":"after","header":setting.name},slot:"after"},[_c('q-icon',{attrs:{"name":"info","size":"sm","color":"neutral-3"}}),_c('div',{attrs:{"slot":"content"},domProps:{"innerHTML":_vm._s(setting.info)},slot:"content"})],1):_vm._e()],1)})],2)}),_c('a',{staticClass:"btn mt-3 bg-neutral-5",on:{"click":function($event){return _vm.set_default_settings('track')}}},[_vm._v(" Reset to default ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }