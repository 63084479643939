import { render, staticRenderFns } from "./Actions.vue?vue&type=template&id=19445b20&scoped=true"
import script from "./Actions.vue?vue&type=script&lang=js"
export * from "./Actions.vue?vue&type=script&lang=js"
import style0 from "./Actions.vue?vue&type=style&index=0&id=19445b20&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19445b20",
  null
  
)

export default component.exports
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QResizeObserver from 'quasar/src/components/resize-observer/QResizeObserver.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QSelect,QItem,QItemSection,QItemLabel,QIcon,QTabs,QTab,QTooltip,QTabPanels,QTabPanel,QResizeObserver,QField});
