var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"turns d-flex justify-content-center bg-neutral-8"},[(_vm.encounter.finished)?_c('h2',[_vm._v("Finished")]):[_c('div',{staticClass:"timer"},[_c('i',{staticClass:"fas fa-stopwatch",attrs:{"aria-hidden":"true"}}),_c('hk-timer',{key:_vm.encounter.turn,attrs:{"value":_vm.timer || 0}})],1),(_vm.encounter.round)?_c('div',{staticClass:"round-info d-flex justify-content-center"},[_c('div',{staticClass:"mr-3"},[_c('div',[_vm._v("Round")]),_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.encounter.round))])]),_c('div',[_c('div',[_vm._v("Turn")]),_c('div',{staticClass:"number"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.turn + 1)),_c('span',{staticClass:"small neutral-4"},[_vm._v("/"+_vm._s(_vm.entities_len))])])])]):_vm._e(),_c('div',{staticClass:"img d-none d-md-block"},[_c('Avatar',{attrs:{"entity":_vm.current,"players":_vm.players,"npcs":_vm.npcs}})],1),_c('h2',{staticClass:"d-none d-md-flex justify-content-start"},[_c('span',{staticClass:"mr-3"},[_c('Name',{attrs:{"entity":_vm.current,"players":_vm.players,"npcs":_vm.npcs,"npcSettings":_vm.npcSettings}})],1),(
					(_vm.playerSettings.health === undefined &&
						(_vm.current.entityType === 'player' || _vm.current.entityType === 'companion')) ||
					_vm.displayNPCField('health', _vm.current) === true
				)?_c('Health',{attrs:{"entity":_vm.current,"campPlayers":_vm.campPlayers,"campCompanions":_vm.campCompanions,"players":_vm.players,"npcs":_vm.npcs}}):(
					(_vm.current.entityType == 'player' && _vm.playerSettings.health === 'obscured') ||
					(_vm.current.entityType == 'npc' && _vm.displayNPCField('health', _vm.current) === 'obscured')
				)?[(_vm.current.curHp == 0)?[_c('i',{staticClass:"fas fa-skull-crossbones red",attrs:{"aria-hidden":"true"}})]:_c('span',[_c('i',{staticClass:"fas",class:{
							'green fa-heart': _vm.percentage(_vm.current.curHp, _vm.current.maxHp) == 100,
							'orange fa-heart-broken':
								_vm.percentage(_vm.current.curHp, _vm.current.maxHp) < 100 &&
								_vm.percentage(_vm.current.curHp, _vm.current.maxHp) > 33,
							'red fa-heartbeat': _vm.percentage(_vm.current.curHp, _vm.current.maxHp) <= 33,
						},attrs:{"aria-hidden":"true"}})])]:_c('span',{staticClass:"neutral-4"},[_vm._v(" ? ? ? ")])],2)],_c('span',{staticClass:"actions"},[(_vm.encounter.weather && Object.keys(_vm.encounter.weather).length > 0)?_c('a',{staticClass:"weather",attrs:{"aria-label":"Toggle weather effects"},on:{"click":_vm.setWeather}},[(_vm.weather)?_c('i',{staticClass:"fas fa-cloud-showers",attrs:{"aria-hidden":"true"}}):_c('i',{staticClass:"fas fa-cloud hide",attrs:{"aria-hidden":"true"}})]):_vm._e(),_c('a',{staticClass:"full",attrs:{"aria-label":"Toggle fullscreen"},on:{"click":function($event){return _vm.$q.fullscreen.toggle()}}},[_c('q-icon',{attrs:{"name":_vm.$q.fullscreen.isActive ? 'fullscreen_exit' : 'fullscreen'}}),_c('q-tooltip',{attrs:{"anchor":"bottom middle","self":"top middle"}},[_vm._v(" Fullscreen ")])],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }