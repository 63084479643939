import { render, staticRenderFns } from "./Finished.vue?vue&type=template&id=7ad20586&scoped=true"
import script from "./Finished.vue?vue&type=script&lang=js"
export * from "./Finished.vue?vue&type=script&lang=js"
import style0 from "./Finished.vue?vue&type=style&index=0&id=7ad20586&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ad20586",
  null
  
)

export default component.exports
import QSlideTransition from 'quasar/src/components/slide-transition/QSlideTransition.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QSlideTransition,QBanner,QIcon,QBtn,QTooltip,QTabs,QTab,QTabPanels,QTabPanel,QInput,QList,QItem,QItemSection});
