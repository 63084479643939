import { render, staticRenderFns } from "./DuplicateOptions.vue?vue&type=template&id=1eeb8628&scoped=true"
import script from "./DuplicateOptions.vue?vue&type=script&lang=js"
export * from "./DuplicateOptions.vue?vue&type=script&lang=js"
import style0 from "./DuplicateOptions.vue?vue&type=style&index=0&id=1eeb8628&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1eeb8628",
  null
  
)

export default component.exports
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QBtnToggle,QTooltip,QIcon});
