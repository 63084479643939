import { render, staticRenderFns } from "./index.vue?vue&type=template&id=c6d84a58&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=c6d84a58&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6d84a58",
  null
  
)

export default component.exports
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';import Ripple from 'quasar/src/directives/Ripple.js';
import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QScrollArea,QSelect,QItem,QItemSection,QIcon,QItemLabel,QTabPanels,QTabPanel});qInstall(component, 'directives', {Ripple,ClosePopup});
