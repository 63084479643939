import { render, staticRenderFns } from "./Current.vue?vue&type=template&id=0fc650e3&scoped=true"
import script from "./Current.vue?vue&type=script&lang=js"
export * from "./Current.vue?vue&type=script&lang=js"
import style0 from "./Current.vue?vue&type=style&index=0&id=0fc650e3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fc650e3",
  null
  
)

export default component.exports
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QTooltip,QScrollArea});
